import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/users",
    title: "Users",
    icon: "sl-icon-user",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/vendors",
    title: "Services Providers",
    icon: "icon-Add-UserStar",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/vendors1",
    title: "Staff",
    icon: "icon-Add-UserStar",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/vendorcategory",
    title: "Categories",
    icon: "sl-icon-grid",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/pages/rewards",
    title: "Coupons",
    icon: "ti-gift",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/pages/revenue",
    title: "Revenue",
    icon: "mdi mdi-chart-areaspline",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/reviews",
    title: "Reviews",
    icon: "mdi mdi-account-star-variant",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/pages/mangenotification",
    title: "Notification",
    icon: "sl-icon-bell",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/pages/cms",
    title: "CMS Pages",
    icon: "icon-Car-Wheel",
    class: "",
    extralink: false,
    submenu: [],
  },

  // {
  //             path: '/pages/Subscription',
  //             title: 'Subscription',
  //             icon: 'icon-Files',
  //             class: '',
  //             extralink: false,
  //             submenu: []
  //         },
  {
    path: "/pages/commission",
    title: "Commission",
    icon: "fas fa-percent",
    class: "",
    extralink: false,
    submenu: [],
  },
];
