import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthguardGuard } from './services/authguard.guard';
import { WebCmsComponent } from './authentication/web-cms/web-cms.component';
import { WebPolicyComponent } from './authentication/web-policy/web-policy.component';
import { EulaComponent } from './authentication/eula/eula.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule),
        canActivate:[AuthguardGuard]
      },

      // { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate:[AuthguardGuard]
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: 'TermsCondition',
    component: WebCmsComponent,

  },
  {
    path: 'privacyPolicy',
    component: WebPolicyComponent,

  },
  {
    path: 'eula',
    component: EulaComponent,

  },
  {
    path: '**',
    redirectTo: '404'
  }
];

