import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'app-web-cms',
  templateUrl: './web-cms.component.html',
  styleUrls: ['./web-cms.component.css']
})
export class WebCmsComponent implements OnInit {



  cmsData: any;
  

  constructor(private Srvc: CmsService, private route: ActivatedRoute) {
    
  }

  ngOnInit() {
    this.getAllCMS();
  }

  getAllCMS() {
    this.Srvc.getTerms().subscribe((res: any) => {
       this.cmsData = res.data.data.legal;
});
  }

}
