import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { catchError, tap } from "rxjs/operators";
import { ApiService } from "./api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  ErrorMessage: any;
  constructor(
    private accountService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((data: any) => {
        this.ErrorMessage = data?.body?.message;
        if (data.body && data.body.statusCode == 400) {
          if (data?.body?.message === "Invalid Token") {
            this.accountService.logout();
            this.toastr.error("Please login again", "Authorization Failed");
            return;
          }
          this.toastr.clear();
          return Observable.throw(data.body.message);
        } else {
          return data.body;
        }
      }),
      catchError((err: any) => {
        if (err.error?.data != null && err.error?.data != undefined) {
          if (typeof err.error.data == "object") {
            var errr = "";
            Object.keys(err.error.data).forEach((key) => {
              if (typeof err.error.data[key] == "object") {
                Object.keys(err.error.data[key]).forEach((key2) => {
                  errr = err.error.data[key][key2];
                });
              }
            });
            if (errr != "") {
              this.toastr.clear();
              this.toastr.error(this.ErrorMessage);

            }
          }
        } else {
          var error =
            err?.error?.message || err.statusText
              ? err?.error?.message || err.statusText
              : this.ErrorMessage;
           this.toastr.clear();
          this.toastr.error(
            error != "Success" ? error : "Invalid http request"
          );

          if (error == "Unknown Error") {
            this.accountService.logout();
          }
        }
        if (err.status === 401) {
          this.toastr.clear();
          this.toastr.error("Not authorized", "Please login again");
          this.accountService.logout();
        }
        var error =
          err.error?.error_description ||
          err.error?.message ||
          err.statusText ||
          err?.message;
        return throwError(error);
      })
    );
  }
}
