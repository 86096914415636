import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  token: string;

  constructor(private spinner:NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
this.token=sessionStorage.getItem('accessToken');
 this.spinner.show();
 if (this.token) {
  request = request.clone({ setHeaders: {
    Authorization: `${this.token}`,
}});
  this.spinner.show();
  return next
    .handle(request)
    .pipe(finalize(() => this.spinner.hide()));
} else {
return next.handle(request).pipe(finalize(() => this.spinner.hide()));
}
}
}
