import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'app-web-policy',
  templateUrl: './web-policy.component.html',
  styleUrls: ['./web-policy.component.css']
})
export class WebPolicyComponent implements OnInit {
  cmsData: any;
  

  constructor(private Srvc: CmsService, private route: ActivatedRoute) {
    
  }

  ngOnInit() {
    this.getAllCMS();
  }

  getAllCMS() {
    this.Srvc.getPrivacy().subscribe((res: any) => {
       this.cmsData = res?.data?.data?.privacy;
});
  }

}
