import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.css']
})
export class EulaComponent implements OnInit {
  cmsData: any;


  constructor(private Srvc: CmsService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getAllCMS();
  }

  getAllCMS() {
    this.Srvc.getEula().subscribe((res: any) => {
       this.cmsData = res?.data?.data?.Eula;
});
  }

}
